.title{
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color:#161616;
    margin-bottom: 80px;
}

.inputLabelDiv{
    margin-bottom: 40px;
    width: 100%;
}
.label{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #696969;
    margin-bottom: 20px;
}
.inputDiv{
    padding-bottom: 10px;
    border-bottom:1px solid #D9D9D9;
    max-width: 751px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.inputStyle{
    color:#161616;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border:none;
    margin-right: 10px;
    width: 100%;
}
.inputStyle::placeholder{
    color:#161616;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.invalid{
    border-bottom: 2px solid #CB2D25;
}
.submitBtn{
    padding: 6px 20px;
    border-radius: 58px;
    background: #107DD7;
    border: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color:#FFF;
    margin: 0 auto 27px;
    min-width: 206px;
}
/*.submitBtn:disabled{*/
/*    border: 2px solid #696969;*/
/*    color: #696969;*/
/*    background: #FFF;*/
/*    !*cursor: not-allowed;*!*/
/*}*/
.notAllowed{
    border: 2px solid #696969;
    color: #696969;
    background: #FFF;
}
.error{
    font-size: 14px;
    line-height: 24px;
    color: #CB2D25;
    max-width: 700px;
    width: 100%;
    margin-top: 7px;
}
.validationDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 751px;
    width: 100%;
    margin-bottom: 50px;
}
.singleValidationDiv{
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.validationType{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #161616;
}
.validationName{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #696969;
}
.valid{
    color: #107DD7;
}

.form{
    max-width: 751px;
    width: 100%;
    display: flex;
    flex-direction: column;
}