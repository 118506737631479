.page_main {
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    background: url("../../assets/images/home_main.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page_main-content {
    padding-bottom: 260px;
    max-width: 80%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.page_main-content_text {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 580px;
    width: 100%;
}

.page_main-content_text .bold {
    font-weight: 700;
}