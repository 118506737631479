.loginContent {
    display: flex;
    height: 100vh;
}
.imageSide {
    background: url("../../assets/images/login-main-image.png") no-repeat center;
    background-size: 100%;
    width: 100%;
    max-width: 1200px;
    position: relative;
}
.imageText{
    position: absolute;
    top: 10%;
    left: 28%;
    display: grid;
    gap: 42px;
    color: #ffffff;
}
.logo {
    font-family: Jost-Bold;
    font-size: 96px;
    font-weight: 900;
    line-height: 139px;
}
.loginContent .imageSide .imageText span {
    font-size: 24px;
    line-height: 35px;
}

.formPart{
    width: 100%;
    max-width: 715px;
    display: grid;
    padding: 40px 70px;
}
.navLanguages{
    display:flex;
    gap: 30px;
    justify-content: flex-end;
}
.navLanguages button {
    color: #95AABB !important;
}

.loginForm{
    max-width: 485px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.loginForm .title {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 18px;
}
.formGroupItem{
    display: flex;
    gap: 26px;
    margin-bottom: 40px;
}

.countryCode ,.phoneNumber, .inputLabelDiv, .passowrdFormGroup {
    display: grid;
    gap: 15px;
}
.phoneNumber{
    width: 100%;
}

.countryCode select {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #95AABB;
    width: 64px;
    font-size: 20px;
    line-height: 29px;
    padding-bottom: 8px;
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
    background: url("../../assets/images/arrow-down.png") no-repeat right;
    background-position: 100% 40%;
}
.countryCode select:focus-visible {
    outline: none;
}

.phoneNumber input, .inputDiv input
.passowrdFormGroup .passwordInput input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #95AABB;
    width: 100%;
    font-size: 20px;
    line-height: 29px;
    padding-bottom: 8px;
}

.passowrdFormGroup .passwordInput input .inputDiv input {
    padding-bottom: 12px;
}

.submitNext1{
    width: 125px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 55px;
    background:  #107DD7;
    cursor: pointer;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #FFF;
    margin:60px auto 0;
}
.disabled{
    border: 2px solid  #95AABB;
    color: #95AABB;
    background-color: #FFF;
}
.invalid{
    border-bottom: 2px solid red !important;
}
.error{
    white-space: nowrap;
    font-size: 14px;
    line-height: 24px;
    color: #CB2D25;
    max-width: 485px;
    width: 100%;
    margin-top: 7px;
}

.inputStyle{
    color:#161616;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border:none;
    margin-right: 10px;
    width: 100%;
}
.inputLabelDiv{
    margin-bottom:20px;
    width: 100%;
}
label{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #000C1F
}
.inputDiv{
    background-color: unset;
    padding-bottom: 10px;
    border-bottom:1px solid #D9D9D9;
    max-width: 751px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.validationDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 751px;
    width: 100%;
    margin-bottom: 50px;
}
.singleValidationDiv{
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.validationType{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #161616;
}

.validationName{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #696969;
}
.valid{
    color: #107DD7;
}

.enabled{
    background-color: red;
}
.notAllowed{
    border: 2px solid #696969;
    color: #696969;
    background: #FFF;
}