@font-face {
    font-family: Jost-Regular;
    src: url("../assets/fonts/Jost-Regular.ttf");
}

@font-face {
    font-family: Jost-Bold;
    src: url("../assets/fonts/Jost-Bold.ttf");
}

@font-face {
    font-family: Jost-SemiBold;
    src: url("../assets/fonts/Jost-SemiBold.ttf");
}