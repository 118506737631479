
.user-header {
    width: 100%;
    height: 100px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    top: 0;
    z-index: 10;
    padding: 17px 0;
    position: fixed;
}

.user-header a {
    color: #ffffff;
}

.user-header nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-header nav ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.user-header nav .logo {
    font-size: 40px;
    font-weight: 900;
    line-height: 58px;
}

.user-header nav .nav-menu {
    width: 100%;
    justify-content: end;
    display: flex;
    align-items: center;
    gap: 30px;
}

.user-header nav .nav-menu li a{
    font-size: 20px;
    line-height: 29px;
    color: #ffffff;
}

.user-header nav .right-side {
    width: 100%;
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    gap: 50px;
}

.user-header nav .right-side .user-details {
    display: flex;
    gap: 20px;
    color: #ffffff;
}

.user-header nav .right-side .user-details .user-image {
    border: 2px solid #107DD7;
    padding: 4px;
    border-radius: 50%;
}

.user-header nav .right-side .user-details .user-image img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
}

.user-header nav .right-side .nav-buttons {
    gap: 30px;
}

.user-header nav .right-side .nav-buttons .notifications {
    position: relative;
}

.user-header nav .right-side .nav-buttons .notifications .notification-num {
    position: absolute;
    right: -15%;
    bottom: -10%;
    width: 20px;
    height: 20px;
    background-color: var(--main_blue);
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-header nav .nav-languages {
    gap: 15px;
}

.user-header nav .nav-languages li:first-child button {
    padding-right: 15px;
}

.user-header nav .nav-languages li:first-child button::after {
    content: "";
    position: absolute;
    right: 0;
    top: 2%;
    width: 1px;
    height: 22px;
    background-color: #ffffff;
}

.user-header nav .nav-languages button {
    color: #ffffff;
    text-transform: uppercase;
    position: relative;
    font-size: 20px;
}

.user-header nav .log-out-btn {
    display: flex;
}

.user-header nav .log-out-btn a {
    color: #ffffff;
    font-size: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.user-header nav .log-out-btn img {
    width: 30px;
    height: 30px;
}
.settings{
    position:relative;
}
.dropdown{
    z-index:100
}
.dropdown-content {
    z-index: 1000;
    position: absolute;
    top:65px;
    right: 10px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.18);
    min-width: 192px;
    display:flex;
    flex-direction: column;
}
.singleSettings{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color:#000 !important;
    text-align: right;
    padding: 12px 20px;
    text-decoration: none;
}

.single {
    white-space: nowrap;
    padding: 12px 20px;
    text-decoration: none;
    display: block;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color:#696969 !important;
    cursor: pointer;
}

.single:hover {
    background-color: #107DD7;
    color:#FFF !important;
}

/*.dropdown:hover .dropdown-content {*/
/*    display: block;*/
/*}*/

/*.dropdown:hover .dropbtn {*/
/*    background-color: #3e8e41;*/
/*}*/