.buttons-list {
    display: flex;
    justify-content: space-between;
    gap: 23px;
    margin: 60px 0;
}

.category-btn {
    color: var(--main_blue);
    border: 1px solid var(--main_blue);
    padding: 8px 17px;
    border-radius: 39px;
    font-size: 18px;
    line-height: 26px;
}

.category-btn.active, .category-btn:hover {
   background-color: var(--main_blue);
   color: #ffffff;
}
.tab-elements {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
  
  .tab-elements.act {
    opacity: 1;
  }

  .elements-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1020px;
    margin: 0 auto;
    gap: 100px;
  }

  .element-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-width: 180px;
    width: 100%;
    text-align: center;
  }
  
  .element-item img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  .element-item span {
    font-size: 20px;
    line-height: 29px;
  }