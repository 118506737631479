.download-section {
    width: 100%;
    height: 600px;
    background: url("../../assets/images/home_mobile.png") no-repeat center;
    background-size: 100%;
    position: relative;
    color: #ffffff;
}

.download-section .content {
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: absolute;
    bottom: 25%;
}

.download-section .content h3 {
    font-family: Jost-Bold;
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 35px;
}

.download-section .content .apps-buttons {
    display: grid;
    gap: 18px;
}