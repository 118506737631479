.notification-bar {
    position: fixed;
    top: 100px;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 546px;
    background-color: #ffffff;
    z-index: 100;
    overflow-y: auto;
    opacity: 0;
    color: #000;
    box-shadow: 0px 4px 24px 0px #0000001A;
    padding-bottom: 110px;
}

.notification-bar::-webkit-scrollbar {
  height: 4px;              
  width: 4px;               
  border: 1px solid #d5d5d5;
  border-radius: 8px;
}

.notification-bar::-webkit-scrollbar-thumb {
  background: #D9D9D9; 
  border-radius: 8px;
}
  
.notification-bar.open {
    opacity: 1;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    margin: 40px 40.5px 10px;
    position: relative;
}

.notification-header h3 {
    font-size: 20px;
    font-weight: 700;
}

.notification-header .items-list {
    display: flex;
    gap: 8px;
}

.notification-header .items-list .item:nth-child(2) {
    position: relative;
    padding-right: 10px;
}

.notification-header .items-list .item:nth-child(2)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 12%;
    width: 1px;
    height: 22px;
    background-color: #CBCBCB;
}

.date-range {
    display: flex;
    width: 100%;
    gap: 25px;
    justify-content: space-between;
    padding: 0 40.5px;
    margin-bottom: 30px;
}

.date-range .react-datepicker-wrapper, .date-range .react-datepicker__input-container input {
    width: 100%;

}

.date-range .react-datepicker__input-container input {
    width: 100%;
    background: url("../../assets/images/calendar.png") no-repeat right;
    background-position: 98%;
    border-bottom: 1px solid #696969;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    font-size: 20px;
    line-height: 29px;
    color: #696969;
    padding: 0 6px;
    cursor: pointer;
}

.notification-bar .search-input {
    padding: 0 40.5px;
}

.notification-bar .search-input input {
    background: url("../../assets/images/search.png") no-repeat right;
    background-position: 98%;
    width: 100%;
    border-bottom: 1px solid #696969;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    font-size: 20px;
    line-height: 29px;
    color: #696969;
    padding: 0 6px;
}

.notification-bar .read-unread {
    display: flex;
    margin-left: auto;
    padding: 20px 40.5px 0;
    align-items: flex-end;
    justify-content: flex-end;
}


.switch {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 110px;
  outline: none;
}

.switch span {
  color: #696969;
  font-size: 20px;
}

.switch input {
  position: absolute;
top: -99999px;
left: -99999px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 38%;
  right: 0;
  bottom: 0;
  background-color: #696969;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  width: 40px;
  height: 8px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 0px;
  bottom: -5px;
  background-color: #C7CAC7;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #63AFFA;
}
input:focus + .slider {
  box-shadow: 0 0 1px #107DD7;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #107DD7;
}

.notifications .divider {
  background-color: #D9D9D9;
  width: 100%;
  height: 1px;
  display: block;
  margin: 30px 0;
}

.notification-list {
  display: grid;
  gap: 20px;
  
}

.notification-list h4 {
  font-size: 16px;
  color: #696969;
  padding: 0 40.5px;
}

.notification-list .single-notification {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  padding: 0 40.5px;
  background: url("../../assets/images/unselected.png") no-repeat left;
  background-size: 20px;
  background-position: 6.8%;
}

.notification-list .single-notification.selected {
 background: url("../../assets/images/selected.png") no-repeat left;
 background-size: 20px;
 background-position: 6.8%;
}

.notification-list .single-notification input[type="checkbox"] {
  opacity: 0;
  visibility: hidden;
}

.notification-list .single-notification .notification-icon {
  max-width: 38px;
  width: 100%;
  height: 38px;
  background-color: #E9E9E9;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-list .single-notification .notification-icon img {
  object-fit: scale-down;
}

.notification-list .single-notification .notification-content {
  display: grid;
  max-width: 100%;
  gap: 4px;
}

.notification-list .single-notification .notification-content .header {
  display: flex;
  justify-content: space-between;
}

.notification-list .single-notification .notification-content .header h3 {
    font-size: 20px;
    font-weight: 700;
    color: #010202;
    margin: 0;
}

.notification-list .single-notification .notification-content .header span,
.notification-list .single-notification .notification-content p  {
  font-size: 16px;
  color: #696969;
}

.dropdown-filter{
    padding-top: 20px;
    padding-bottom: 20px;
    background: #FFF;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.20);
    position: absolute;
    top: 30px;
    right:10px;
    z-index: 150;
    min-width: 243px;
    padding-left: 0 !important;
}
.singleFilter{
    cursor: pointer;
    padding: 6px 20px 6px 20px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #696969;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.iconStyle{
    max-width:22px;
    width: 100%;
    margin-right: 8px;
}

.active{
    background-color: #107DD7;
    color:white
}