.multisteps-form {
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
}
/*.multisteps-form {*/
/*    margin: 140px 0 117px;*/
/*}*/
.multisteps-form .title {
    display: grid;
    gap: 19px;
    margin-bottom: 88px;
}

.multisteps-form .title h1 {
    font-family: Jost-Bold;
    font-size: 32px;
    line-height: 46px;
}

.multisteps-form .title span {
    font-size: 18px;
    line-height: 26px;
    color: var(--dark_blue);
}

.form-steps > div {
    gap: 0 80px;
    margin-left: 100px;
    display: grid !important;
    grid-template-columns: 0.5fr 2fr;
}

.form-steps > div .go169520481 {
    display: grid;
    gap: 94px;
    padding: 0;
}

.form-steps > div .go169520481 span {
    color: transparent;
}

.form-steps > div .go169520481 span::before {
    left: 0%;
    position: absolute;
    color: black;
    font-family: Jost-Bold;
    font-size: 20px;
}

.form-steps > div .go169520481 .go2150698616:nth-child(1) span::before {
    content: "Start";
}

.form-steps > div .go169520481 .go2150698616:nth-child(2) span::before {
    content: "Finance";
}


.form-steps > div .go169520481 .go2150698616:nth-child(3) span::before {
    content: "Rent";
}

.form-steps > div .go169520481 .go2150698616:nth-child(4) span::before {
    content: "Question";
}

.form-steps > div .go169520481 .go2150698616 {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    border: none;
}

.form-steps > div .go169520481 .go2150698616::after {
    position: absolute;
    content: "";
    height: 74px;
    width: 2px;
    background-color: #D9D9D9;
    top: 112%;
    left: 67%;
}

.form-steps > div .go169520481 .go2150698616:last-of-type::after {
    background-color: transparent;
}

.form-steps > div .go169520481 .go2150698616:before {
    bottom: 0;
    width: 70px;
    height: 70px;
    content: "";
}

.go433304200:before {
    content: none;
}

.go2150698616:nth-child(1):before {
    background: url("../../assets/images/icons/start-step-icon.png") no-repeat right;
    background-size: 70px;
}

.go2150698616:nth-child(2):before {
    background: url("../../assets/images/icons/finance-icon.png") no-repeat right;
    background-size: 70px;
}

.go2150698616:nth-child(3):before {
    background: url("../../assets/images/icons/rent-steps-icon.png") no-repeat right;
    background-size: 70px;
}

.go2150698616:nth-child(4):before {
    background-color: #95AABB;
}

.go3842760039:nth-child(2)::before {
    background: url("../../assets/images/icons/Finance_ selected.png") no-repeat right;
    background-size: 70px;
}

.go3842760039:nth-child(3)::before {
    background: url("../../assets/images/icons/Rent_ selected.png") no-repeat right !important;
    background-size: 70px;
}


.go433304200:nth-child(1)::before {
    content: "•";
    color: transparent;
    background-color: transparent;
    background: url("../../assets/images/icons/start-step-icon.png") no-repeat right;
    background-size: 70px;
}

.go433304200:nth-child(2)::before {
    background: url("../../assets/images/icons/Finance_ selected.png") no-repeat right;
    background-size: 70px;
}

.go433304200:nth-child(3)::before {
    background: url("../../assets/images/icons/Rent_ selected.png") no-repeat right;
    background-size: 70px;
}

.go2335061104:before {
    content: none;
}

.form-steps > div > div {
    margin-top: 20px;
}

.form-steps > div > div:last-of-type {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 4;
    margin-left: auto;
    margin-top: -55px;
}

.form-steps > div > div:last-of-type div {
    display: flex;
    gap: 40px;
}

.form-steps > div > div:last-of-type div button {
    border: 1px solid #95AABB;
    background-color: var(--main_blue);
    color: #ffffff;
    width: 125px;
    height: 37px;
    font-family: Jost-Bold;
    font-size: 20px;
    line-height: 29px;
    margin: 60px auto 0;
    border-radius: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-steps > div > div:last-of-type div button:disabled {
    border: 1px solid #95AABB;
    color: #95AABB;
    background-color: transparent;
    cursor: no-drop;
}

.step-list {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.step-item {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.step-item.second {
    max-width: 700px;
    height: 589px;
}

.step-item.second .cloned-items {
    display: grid;
    gap: 32px;
    overflow-y: scroll;

    padding-right: 25px;
}

.step-item.second .cloned-items::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
}

.step-item.second .cloned-items::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 8px;
}

.step-item.second .second-step-form {
    display: flex;
    flex-direction: column;
    gap: 20px;


}

.step-item .step-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.step-item .step-title h2 {
    font-family: Jost-Bold;
    font-size: 24px;
    line-height: 35px;
}

.step-item .step-title button img {
    width: 18px;
    height: 18px;
}

.step-item .checkbox-list {
    display: flex;
    gap: 80px;
}

.step-item .checkbox-list.rentHouse {
    display: grid;
    gap: 34px;
    max-width: 689px;
}

.step-item .checkbox-list .checkbox-item {
    display: flex;
    align-items: center;
    gap: 80px;
    font-size: 18px;
    line-height: 26px;
}

.label-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    line-height: 26px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.label-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 32%;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.label-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.label-container input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid #107DD7;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.label-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.label-container .checkmark:after {
    top: 1px;
    left: 1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #107DD7;
}

.step-form-item {
    display: grid;
    gap: 10px;
    width: 100%;
}

.step-form-item .toping {
    display: flex;
    justify-content: space-between;
}

.step-form-item .toping .salary-amount {
    border: 2px solid #E9E9E9;
    padding: 6px 10px;
    font-size: 18px;
    line-height: 26px;
    color: #95AABB;
    width: 140px;
    height: 38px;
    text-align: center;
}

.step-form-item .toping .salary-amount.active {
    color: var(--dark_blue);
}

.step-form-item label {
    font-size: 18px;
    line-height: 26px;
}

.step-form-item .workplace {
    border: 1px solid #D9D9D9;
    padding: 7px 10px;
    font-size: 18px;
    line-height: 26px;
}

.step-form-item .workplace::placeholder {
    color: #D9D9D9;
}

.step-form-item input[type=range] {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}

.step-form-item input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #D9D9D9;
    border-radius: 1px;
    border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #D9D9D9;
    height: 14px;
    width: 14px;
    border-radius: 25px;
    background: var(--main_blue);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #D9D9D9;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #D9D9D9;
    border-radius: 1px;
    border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #D9D9D9;
    height: 2px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #D9D9D9;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
    background: #D9D9D9;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #D9D9D9;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #D9D9D9;
}
input[type=range]:focus::-ms-fill-upper {
    background: #D9D9D9;
}
