.main-header {
    width: 100%;
    height: 100px;
    background: rgba(0, 0, 0, 0.49);
    -webkit-backdrop-filter: blur(5.25px);
    backdrop-filter: blur(5.25px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 10;
}

.main-header nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent !important;
}

.main-header nav .logo {
    font-family: Jost-Bold;
    color: #ffffff;
    font-size: 40px;
    font-weight: 900;
}

.main-header nav .nav-languages, .login-content .form-part .nav-languages {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 0;
    padding: 0;
}

.main-header nav .nav-languages button,.login-content .form-part .nav-languages button {
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
}

.main-header nav .nav-buttons {
    display: flex;
    align-items: center;
    max-width: 162px;
    width: 100%;
    padding: 6px 20px;
    background-color: var(--main_blue);
    color: #ffffff;
    border-radius: 39px;
    font-size: 18px;
    line-height: 26px;
    gap: 25px;
}

.main-header nav .nav-buttons a {
    color: #ffffff;
    position: relative;
}

.main-header nav .nav-buttons a:nth-child(1)::after {
    content: "";
    color: #ffffff;
    width: 1px;
    height: 18px;
    position: absolute;
    right: -28%;
    top: 15%;
    background: #ffffff;
}